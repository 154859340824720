import React, { useRef, useState } from "react";

import { FormInstance } from "antd/lib/form";
import {
  CloseCircleTwoTone,
  GiftOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Popover,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";

import DynamicImg from "./DynamicImg";
import MenuItemButton from "./MenuItemButton";
import NumberBar from "./NumberBar";
import ComboItemSelector from "./ComboItemSelector";
import { ComboChoices, CustomerInfo, ImageManifest } from "../types/api";
import { ItemSchema, ItemModificationChoice } from "../types/model";
import { PaidLineItem, RedeemedLineItem, IDAndName } from "../types/ui";
import { convertPrice, vowels, getImageManifestForItem } from "../utils/Util";

import styles from "../css/MenuItem.module.css";
const { Paragraph, Text, Title } = Typography;

const { Option } = Select;
const { TextArea } = Input;
type ValidateStatus = Parameters<typeof Form.Item>[0]["validateStatus"];

type MenuItemProps = {
  // If user is logged in, customer is non-null.
  customer: CustomerInfo | null;

  // Total number of points on redeemed items in the card.
  pointsInCart: number;

  item: ItemSchema;
  onAddToCart: (lineItem: PaidLineItem | RedeemedLineItem) => void;

  fulfillmentType: string;
};

export default ({
  customer,
  pointsInCart,
  item,
  onAddToCart,
  fulfillmentType,
}: MenuItemProps) => {
  const initialMods: { [key: string]: string } = {};
  let initialPrice: number = item.price;
  const modificationMap = new Map();
  if (item.modification_schemas) {
    item.modification_schemas.forEach((mod) => {
      if (mod.choices) {
        const sortedChoices = [...mod.choices].sort(
          (choice1, choice2) => choice1.seq - choice2.seq,
        );
        mod = { ...mod, choices: sortedChoices };

        // Populate modificationMap
        const choicesMap = new Map();
        mod.choices.forEach((choice) => {
          let description = choice.description;
          if (choice.price_adjustment) {
            description = `${description} (+${convertPrice(
              choice.price_adjustment,
            )})`;
          }
          choicesMap.set(choice.id, {
            description,
            price_adjustment: choice.price_adjustment,
          });

          if (choice.default_selected && !initialMods[mod.id]) {
            initialMods[mod.id] = choice.id;
            initialPrice += choice.price_adjustment;
          }
        });
        modificationMap.set(mod.id, choicesMap);
      }
    });
  }
  const initialQty = 1;
  const initialNotes = "";
  const getChoiceInfo = (modID: string, choiceID: string) =>
    modificationMap.get(modID)?.get(choiceID);

  const [showModal, setShowModal] = useState(false);
  const [qty, setQty] = useState(initialQty);
  const [notes, setNotes] = useState(initialNotes);
  const [modifications, setModifications] = useState<
    Map<string, string | string[]>
  >(new Map(Object.entries(initialMods)));
  const [comboChoices, setComboChoices] = useState<ComboChoices>({});
  const [comboChoiceValidateState, setComboChoiceValidateState] = useState<{
    validateStatus?: ValidateStatus;
    errorMsg?: string;
  }>({});
  const formRef = useRef<FormInstance>(null);

  // If combo_config is set, don't allow submit until user has selected
  // appropriate number items for the combo.
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(
    item.combo_config === undefined,
  );

  const qtyName = `${item.id}-qty`;
  const notesName = `${item.id}-notes`;
  const comboSelectionName = `${item.id}-combo-selection`;
  const initialValues: { [key: string]: number | string | ComboChoices } = {};
  initialValues[qtyName] = 1;
  Object.entries(initialMods).forEach(([key, value]) => {
    initialValues[key] = value;
  });
  initialValues[comboSelectionName] = {};

  // Get descriptions of selected modifications and calculate total item price.
  let adjustedPrice = item.price;
  const modChoices: IDAndName[] = [];
  for (const [modID, choices] of modifications.entries()) {
    if (typeof choices === "string") {
      const choiceInfo = getChoiceInfo(modID, choices);
      if (choiceInfo !== undefined) {
        modChoices.push({
          id: choices,
          name: choiceInfo.description,
        });
        adjustedPrice += choiceInfo.price_adjustment;
      }
    } else {
      for (const choice of choices) {
        const choiceInfo = getChoiceInfo(modID, choice);
        if (choiceInfo !== undefined) {
          modChoices.push({
            id: choice,
            name: choiceInfo.description,
          });
          adjustedPrice += choiceInfo.price_adjustment;
        }
      }
    }
  }

  const itemForm = (
    <Form
      labelAlign="left"
      layout="vertical"
      initialValues={initialValues}
      requiredMark={false}
      colon={false}
      ref={formRef}
      onValuesChange={(
        changedValues,
        allValues: { [name: string]: number | string | string[] },
      ) => {
        Object.entries(allValues).forEach(
          ([name, value]: [
            string,
            number | string | string[] | ComboChoices,
          ]) => {
            if (name === qtyName) {
              setQty(value as number);
            } else if (name === notesName) {
              setNotes(value as string);
            } else if (name === comboSelectionName) {
              const m = value as ComboChoices;
              setComboChoices(m);

              let total = 0;
              for (const [id, choice] of Object.entries(m)) {
                total += choice.quantity;
              }
              const expectedQty = item.combo_config?.num_items ?? 0;
              if (total === expectedQty) {
                setComboChoiceValidateState({ validateStatus: "success" });
                setSubmitEnabled(true);
              } else {
                setComboChoiceValidateState({
                  validateStatus: "error",
                  errorMsg: `Must select ${expectedQty} items / 必須選擇 ${expectedQty} 包`,
                });
                setSubmitEnabled(false);
              }
            } else {
              setModifications((prev) => {
                const copy = new Map(prev);
                if (value) {
                  copy.set(name, value as string | string[]);
                } else {
                  copy.delete(name);
                }
                return copy;
              });
            }
          },
        );
      }}
      style={{ width: "100%" }}
    >
      <Form.Item
        label="Quantity / 數量"
        name={qtyName}
        hidden={item.combo_config !== undefined}
      >
        <NumberBar min={1} />
      </Form.Item>
      {item.modification_schemas &&
        item.modification_schemas.length > 0 &&
        item.modification_schemas.map((mod) => {
          const choices = mod.choices.map((choice) => (
            <Option key={choice.id} value={choice.id}>
              {choice.description}
              {choice.price_adjustment !== 0 && (
                <Text type="secondary">
                  {" "}
                  +{convertPrice(choice.price_adjustment)}
                </Text>
              )}
            </Option>
          ));

          let modArticle = "a";
          if (
            mod.description &&
            mod.description.length > 0 &&
            vowels.indexOf(mod.description[0]) !== -1
          ) {
            modArticle = "an";
          }

          let description = mod.description;
          let mode: undefined | "multiple" = undefined;
          let allowClear = false;
          let rules = [
            {
              required: true,
              message: `Please select ${modArticle} ${mod.description.toLowerCase()}`,
              min: 1,
            },
          ];
          if (mod.allow_multiple_choice) {
            description = `${mod.description} (multiple can be selected/可複選)`;
            mode = "multiple";
          }
          if (mod.optional) {
            rules = [];
            allowClear = true;
          }

          return (
            <Form.Item
              key={mod.id}
              label={description}
              name={mod.id}
              rules={rules}
            >
              <Select
                mode={mode}
                allowClear={allowClear}
                showSearch={false}
                size="large"
              >
                {choices}
              </Select>
            </Form.Item>
          );
        })}
      <Form.Item
        name={comboSelectionName}
        validateStatus={comboChoiceValidateState.validateStatus}
        help={comboChoiceValidateState.errorMsg ?? ""}
        hidden={item.combo_config === undefined}
      >
        {item.combo_config !== undefined && (
          <ComboItemSelector comboConfig={item.combo_config} />
        )}
      </Form.Item>
      <Form.Item
        label="Notes / 備註"
        name={notesName}
        help={
          <Alert
            type="error"
            message="Please describe any allergies you have. 若有過敏請於備註中告知"
            style={{ marginTop: "16px" }}
          />
        }
        hidden={fulfillmentType === "SHIP" || fulfillmentType === "GROUP" || fulfillmentType === "CAMPAIGN_BATCH_DELIVERY"}
      >
        <TextArea
          rows={4}
          placeholder="Any special instructions"
          showCount
          maxLength={128}
        />
      </Form.Item>
      {/* Don't show quantity selector for combo item since the combo item
          choices have quantity selector too, and having multiple is confusing.
          This means we always add combo items to the cart 1 at a time.
        */}
    </Form>
  );

  const onClick = () => setShowModal(true);

  const resetItemState = () => {
    // Reset item state
    setQty(initialQty);
    setNotes(initialNotes);
    setModifications(new Map(Object.entries(initialMods)));
    if (formRef.current) {
      formRef.current!.resetFields();
    }
  };

  const onClickAddToCart = () => {
    formRef
      .current!.validateFields()
      .then((values) => {
        onAddToCart({
          item_type: "paid",
          item_schema: item,
          modifications: modChoices,
          combo_choices: comboChoices,
          notes: notes,
          quantity: qty,
          price: adjustedPrice,
          image_manifest: getImageManifestForItem(item),
        });
        setShowModal(false);
        resetItemState();
      })
      .catch((errorInfo) => console.log(errorInfo));
  };

  const onClickRedeem = (points: number) => {
    return () => {
      formRef
        .current!.validateFields()
        .then((values) => {
          onAddToCart({
            item_type: "redeemed",
            item_schema: item,
            modifications: modChoices,
            combo_choices: comboChoices,
            notes: notes,
            quantity: qty,
            points: points,
            image_manifest: getImageManifestForItem(item),
          });
          setShowModal(false);
          resetItemState();
        })
        .catch((errorInfo) => console.log(errorInfo));
    };
  };

  const itemLabel = item.pos_key;

  // Fallback for old item schemas.
  const imgManifest: ImageManifest | undefined = getImageManifestForItem(item);
  return (
    <div style={{ height: "100%" }}>
      <MenuItemButton
        pos_key={item.pos_key}
        name={item.name}
        imgManifest={imgManifest}
        price={initialPrice}
        points={item.points}
        soldOut={item.sold_out}
        onClick={onClick}
      />
      {/* removed padding in index.css; currently does not have customization */}
      <Modal
        open={showModal}
        closeIcon={
          <CloseCircleTwoTone
            twoToneColor="#DEC623"
            style={{ fontSize: "32px" }}
          />
        }
        onCancel={() => setShowModal(false)}
        footer={
          <Space
            size="middle"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            {!item.sold_out &&
              item.points &&
              redeemButton(
                customer,
                pointsInCart,
                item.points * qty,
                onClickRedeem(item.points),
              )}
            <Button
              type="primary"
              size="large"
              disabled={item.sold_out || !submitEnabled}
              onClick={onClickAddToCart}
            >
              <ShoppingCartOutlined />{" "}
              {item.sold_out ? "Sold Out" : "Add to Cart"}
            </Button>
          </Space>
        }
        styles={{
          footer: {
            paddingBottom: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            margin: 0,
          },
        }}
        // style={{ maxWidth: "90%" }}
      >
        {imgManifest && (
          <div style={{ display: "flex", width: "100%", maxHeight: "300px" }}>
            <DynamicImg
              alt={item.name}
              manifest={imgManifest}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "6px 6px 0 0",
              }}
            />
          </div>
        )}
        <Flex
          vertical
          align="flex-start"
          justify="center"
          gap={10}
          style={{ width: "100%", padding: "1rem" }}
        >
          {imgManifest && (
            <Text type="secondary">
              All images shown are for illustration purpose only, actual product
              may vary. 實際產品可能會與圖片不同
            </Text>
          )}
          <Flex align="center" gap={2}>
            <Tag bordered={false} style={{ fontSize: "30px", padding: "6px" }}>
              {itemLabel}
            </Tag>
            {item.notes && <Alert showIcon type="info" message={item.notes} />}
          </Flex>
          <Title
            level={3}
            style={{
              width: "100%",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {item.name}
          </Title>
          <Flex align="center" justify="flex-start">
            <Title level={5}>${convertPrice(adjustedPrice)}</Title>
            {item.points && (
              <Title level={5}>
                &ensp;|&ensp;
                <GiftOutlined />
                &nbsp;{item.points} points
              </Title>
            )}
          </Flex>
          {fulfillmentType === "SHIP" && (
            <Alert
              type="info"
              message="Item may partially thaw during shipment. 運送過程中可能會稍微退冰"
              style={{ width: "100%" }}
            />
          )}
          {item.sold_out ? (
            <Alert
              type="warning"
              message="We're sorry, this item is currently sold out."
              showIcon
              style={{ width: "100%" }}
            />
          ) : (
            itemForm
          )}
        </Flex>
      </Modal>
    </div>
  );
};

function redeemButton(
  customer: CustomerInfo | null,
  pointsInCart: number,
  itemPoints: number,
  onClick: () => void,
): React.ReactElement {
  const button = (
    <Button
      type="primary"
      size="large"
      disabled={
        itemPoints <= 0 ||
        customer === null ||
        customer.points < itemPoints + pointsInCart
      }
      onClick={onClick}
    >
      <GiftOutlined /> Redeem with {`${itemPoints}`} Points
    </Button>
  );

  if (customer) {
    if (customer.points < itemPoints + pointsInCart) {
      return <Popover title="You don't have enough points.">{button}</Popover>;
    }
    return button;
  }
  return <Popover title="Login to redeem with points!">{button}</Popover>;
}
